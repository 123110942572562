import styled from 'styled-components';
import { toRem, baseVariables } from '../../styles';

export const StyledIconBlock = styled.div<{ contentAlignment: string; fontIcon: string; iconSize?: string }>`
  display: flex;
  height: 100%;
  overflow: hidden;
  border: ${toRem(1)} solid ${baseVariables.color['neutral20']};
  border-radius: ${toRem(14)};
  box-shadow: ${baseVariables.shadows['shadowSubtle04']};
  justify-content: ${props => props.contentAlignment?.toLowerCase()};
  .iconblock {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: ${props => (props.contentAlignment?.toLowerCase() === 'left' ? 'start' : 'center')};
    @media ${baseVariables.mediaQuery.sm} {
      margin: ${toRem(32)};
    }
    margin: ${toRem(16)} ${toRem(16)} ${toRem(8)};
    &-content {
      overflow: hidden;
      &.iconblock-icon {
        display: flex;
        justify-content: ${props => props.contentAlignment?.toLowerCase()};
        align-items: center;
        margin-bottom: ${toRem(8)};
        height: ${toRem(48)};
        .icon-custom {
          &:before {
            display: flex;
            align-items: center;
            justify-content: center;
            ${({ iconSize }) => {
              const size = iconSize === 'small' ? '24px' : '40px';
              const iconFontSize = iconSize === 'small' ? '20px' : '30px';
              return `
                font-size: ${iconFontSize};
                height: ${size};
                width: ${size};
              `;
            }}
          }
        }
      }
      &.iconblock-img {
        img {
          max-height: ${toRem(96)};
        }
        @media ${baseVariables.mediaQuery.sm} {
          margin-bottom: ${toRem(20)};
        }
        margin-bottom: ${toRem(16)};
      }
    }
    .iconblock-body {
      display: flex;
      flex-direction: column;
      align-items: ${props => (props.contentAlignment?.toLowerCase() === 'left' ? 'start' : 'center')};
      white-space: initial;
      flex: 1;
      .heading {
        padding-bottom: ${toRem(8)};
        max-height: ${toRem(64)};
      }
      .truncate-text {
        text-align: ${props => props.contentAlignment?.toLowerCase()};
        overflow: hidden;
      }
      .description {
        padding-bottom: ${toRem(8)};
      }
      .iconblock-button {
        margin-top: ${toRem(11.5)};
        margin-bottom: ${toRem(11.5)};
        width: fit-content;
      }
      ul {
        padding: 0;
        margin-bottom: ${toRem(16)};
        li {
          list-style: none;
          display: flex;
          gap: ${toRem(8)};
          margin-bottom: ${toRem(8)};
          p {
            margin-bottom: 0;
            line-height: 18px;
          }
          &:last-child {
            margin-bottom: 0;
          }
          span {
            &::before {
              font-size: 16px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
`;
export const StyledModalBody = styled.div<{ contentAlignment: string; fontIcon: string; iconSize?: string }>`
  .heading {
    padding-bottom: ${toRem(4)};
    max-height: ${toRem(64)};
  }
  .truncate-text {
    overflow: hidden;
    color: unset;
  }
  .description {
    padding-bottom: ${toRem(16)};
    color: unset;
  }
  .iconblock-button {
    margin-top: auto;
    width: fit-content;
  }
  ul {
    padding: 0;
    margin-bottom: ${toRem(16)};
    li {
      list-style: none;
      display: flex;
      gap: ${toRem(8)};
      margin-bottom: ${toRem(8)};
      p {
        margin-bottom: 0;
        line-height: 18px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      span {
        &::before {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }
`;

export const StyledModalFooter = styled.div`
  @media ${baseVariables.mediaQuery.smallMobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    button {
      margin-bottom: 8px;
      width: 85%;
    }
    a {
      font-size: 0.8125rem;
      font-weight: 700;
      line-height: 1rem;
      -webkit-text-decoration: none;
      text-decoration: none;
      display: inline-block;
      position: relative;
      margin-right: none;
      border-bottom: 1px solid;
    }
    a:after {
      content: none;
    }
  }
  @media ${baseVariables.mediaQuery.allTablets} {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    justify-content: end;
    button {
      margin-bottom: unset;
      width: unset;
    }
  }
`;
