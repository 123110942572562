import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import { Button } from '../../atoms/Button';
import { Heading } from '../../atoms/Heading';
import { Icon } from '../../atoms/Icon';
import { Image } from '../../atoms/Image';
import { Text } from '../../atoms/Text';
import { RichText } from '../RichText';
import { headingType, size, tags } from '../../utils/enums/enums';
import { Modal } from '../Modal';

import { IconBlockProps } from './IconBlock.types';
import { StyledIconBlock, StyledModalBody, StyledModalFooter } from './IconBlock.styles';
import { eventUtil } from '../../utils/eventUtil';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IconBlock = forwardRef((props: IconBlockProps, forwardedRef: any) => {
  const {
    variation,
    contentAlignment,
    openInNewTab,
    ctaLink,
    header,
    headerTag,
    descriptionText,
    ctaType,
    ctaLinkText,
    assetVariation,
    iconPath,
    dynamicMedia,
    trackingProperties,
    styleclass,
    componentId = '',
    openInNewWindowAriaLabel = '',
    iconType,
    isRichText = false,
    detailListItems,
    iconSize = 'large',
    headerFontSize = 't-subtitle-m',
    descriptionFontSize = 't-font-s',
    customCtaAction = false, // Custom Action will override default functionality of CTA Links.
    backToRoomCTAText,
    ctaLinkTextMobile,
    descriptionTextMobile,
  } = props;

  const renditions = dynamicMedia?.renditions;
  const [viewPort, setViewPort] = useState('Desktop');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const isDesktopView = viewPort === 'Desktop';
  const isMobileView = viewPort === 'Mobile';
  const isTabletView = viewPort === 'Tablet';
  const headerFont = headerFontSize === 't-subtitle-l' ? size.large : size.medium;
  const modalHeaderFont = (isMobileView ? 't-subtitle-l' : 't-subtitle-xl') + ' standard';
  const desFontSize = descriptionFontSize === 't-font-xs' ? size.extraSmall : size.small;
  const ctaLinkLabel = useMemo(() => {
    // If ctaLinkTextMobile is authored then only this functionality works.
    // Else ctaLinkLabel will always have ctaLinkText from AEM.
    if (ctaLinkTextMobile) {
      return !isDesktopView ? ctaLinkTextMobile : ctaLinkText;
    } else {
      return ctaLinkText;
    }
  }, [isDesktopView, ctaLinkText, ctaLinkTextMobile]);

  const getDescriptionText = useCallback(
    (isModal = false) => {
      if (descriptionTextMobile && !isModal) {
        return !isDesktopView ? descriptionTextMobile : descriptionText || '';
      } else {
        return descriptionText || '';
      }
    },
    [descriptionText, descriptionTextMobile, isDesktopView]
  );

  const handleResize = () => {
    const xs = window.matchMedia('(max-width: 767px)');
    const md = window.matchMedia('(min-width: 768px) and (max-width: 991px)');
    const lg = window.matchMedia('(min-width: 992px)');
    if (xs?.matches) {
      setViewPort('Mobile');
    } else if (md?.matches) {
      setViewPort('Tablet');
    } else if (lg?.matches) {
      setViewPort('Desktop');
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const tertiaryBtnClass =
    ctaType === 'tertiaryLink'
      ? openInNewTab
        ? 'm-link-tertiary-button m-link-tertiary-button-external t-background-color'
        : 'm-link-tertiary-button t-background-color'
      : '';
  const secondaryBtnClass =
    ctaType === 'secondaryButton'
      ? openInNewTab
        ? 'm-button-s m-button-secondary m-button-external'
        : 'm-button-s m-button-secondary'
      : '';
  const primaryBtnClass =
    ctaType === 'primaryButton'
      ? openInNewTab
        ? 'm-button-s m-button-primary m-button-external'
        : 'm-button-s m-button-primary'
      : '';
  const isLinkValue = ctaType === 'tertiaryLink' ? true : false;
  const customAttributes = trackingProperties?.enableScrollingBehavior ? { 'data-section-tracking': componentId } : {};

  const buttonCallback = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>, isModal = false) => {
    if (customCtaAction) {
      e.preventDefault();
      if (isModal || isDesktopView) {
        eventUtil.dispatch('showHotelDetailModal', {
          id: 'showHotelDetailModal',
        });
      } else {
        setModalOpen(true);
      }
    }
    return isLinkValue ? {} : window.open(ctaLink, openInNewTab ? '_blank' : '_self');
  };

  const contenAlignUpdated =
    assetVariation === 'iconfont' ? (isMobileView || isTabletView ? 'center' : 'left') : contentAlignment;

  const renderDetailListItems = () => {
    return (
      <ul>
        {Array.isArray(detailListItems) &&
          detailListItems.slice(0, 5).map((item, index) => (
            <li key={index}>
              {item?.iconFontPath && (
                <Icon
                  iconClass={clsx(item?.iconFontPath, iconType === 'iconDecorative' ? 'icon-decorative' : '')}
                  aria-live={item?.iconFontPath}
                  ariaLabel={item?.iconFontPath}
                />
              )}
              {item?.text && (
                <RichText text={item?.text} componentId={`${componentId}-descriptiontext`} customClass="t-font-xs" />
              )}
            </li>
          ))}
      </ul>
    );
  };

  const renderDescription = (isModal = false) => {
    return (
      descriptionText &&
      (isRichText ? (
        <RichText
          text={getDescriptionText(isModal)}
          componentId={componentId}
          customClass={clsx('truncate-text', 'description t-font-m', descriptionFontSize, isDesktopView ? 'mt-2' : '')}
        />
      ) : (
        <Text
          customClass={clsx('truncate-text', 'description t-font-m', isDesktopView ? 'mt-2' : '')}
          copyText={getDescriptionText(isModal)}
          fontSize={desFontSize}
          element={tags.div}
        />
      ))
    );
  };
  return (
    <>
      <StyledIconBlock
        data-component-name="m-ui-library-IconBlock"
        data-testid="ui-library-IconBlock"
        className={`col-12 p-0 ${styleclass ? styleclass : ''}`}
        contentAlignment={contenAlignUpdated}
        fontIcon={iconPath || ''}
        iconSize={iconSize}
      >
        <div className={`iconblock`} data-variation={variation} data-testid="icon-block" {...customAttributes}>
          {assetVariation === 'iconfont' && (
            <div className="iconblock-content iconblock-icon">
              <Icon
                iconClass={clsx(iconPath, iconType === 'iconDecorative' ? 'icon-decorative' : '', 'icon-custom')}
                ariaLabel={iconPath}
              />
            </div>
          )}
          {assetVariation === 'image' && (
            <div className="iconblock-content iconblock-img">
              <Image
                altText={dynamicMedia?.altText}
                renditions={renditions}
                dynamic={dynamicMedia?.dynamic}
                defaultImageURL={dynamicMedia?.assetPath}
              />
            </div>
          )}

          <div className="iconblock-body">
            <Heading
              element={headerTag}
              variation={headingType.subtitle}
              fontSize={headerFont}
              titleText={header || ''}
              disableCustomClickTrack
              customClass={'truncate-text heading m-0 '}
            />
            {renderDescription(false)}

            {!(isMobileView || isTabletView) && detailListItems && renderDetailListItems()}
            {ctaLinkText && ctaLink && (
              <Button
                href={ctaLink}
                isLink={isLinkValue}
                testId="ctaLinkButton"
                className={`iconblock-button ${primaryBtnClass} ${secondaryBtnClass} ${tertiaryBtnClass}`}
                target={`${openInNewTab ? '_blank' : '_self'}`}
                linkType={`${openInNewTab ? 'external' : ''}`}
                rel={`${openInNewTab ? 'noopener noreferrer' : ''}`}
                ref={forwardedRef}
                buttonCopy={ctaLinkLabel}
                trackingProperties={trackingProperties}
                custom_click_track_value={
                  trackingProperties?.clickTrack
                    ? `${trackingProperties?.location}|${trackingProperties?.trackingDescription}|${
                        openInNewTab ? 'external' : 'internal'
                      }`
                    : ''
                }
                isTextBeforeChildren={false}
                callback={e => {
                  buttonCallback(e, false);
                }}
                linkAriaLabelOpenNewWindow={openInNewWindowAriaLabel}
              />
            )}
          </div>
        </div>
      </StyledIconBlock>
      {customCtaAction && (
        <Modal
          show={modalOpen}
          popupOpenState={modalOpen}
          setPopupOpenState={setModalOpen}
          handleBlur={true}
          role="dialog"
        >
          <Modal.Header
            labelText={header || ''}
            popupHeaderOnCLoseFunc={setModalOpen}
            customHeadingClass={modalHeaderFont}
          ></Modal.Header>
          <Modal.Body>
            <StyledModalBody contentAlignment={contenAlignUpdated} fontIcon={iconPath || ''} iconSize={iconSize}>
              {renderDescription(true)}
              {detailListItems && renderDetailListItems()}
            </StyledModalBody>
          </Modal.Body>
          <Modal.FooterGeneric customClass="d-none" className="icon-block-modal-footer">
            <StyledModalFooter>
              <Button
                testId="backToRoomCTA"
                className={'m-button-primary'}
                buttonCopy={backToRoomCTAText}
                callback={() => {
                  setModalOpen(false);
                }}
              />
              {ctaLinkText && ctaLink && (
                <Button
                  testId="ctaLinkButtonModal"
                  href={ctaLink}
                  isLink={isLinkValue}
                  className={`iconblock-button ${primaryBtnClass} ${secondaryBtnClass} ${tertiaryBtnClass}`}
                  target={`${openInNewTab ? '_blank' : '_self'}`}
                  linkType={`${openInNewTab ? 'external' : ''}`}
                  rel={`${openInNewTab ? 'noopener noreferrer' : ''}`}
                  ref={forwardedRef}
                  buttonCopy={ctaLinkText}
                  trackingProperties={trackingProperties}
                  custom_click_track_value={
                    trackingProperties?.clickTrack
                      ? `${trackingProperties?.location}|${trackingProperties?.trackingDescription}|${
                          openInNewTab ? 'external' : 'internal'
                        }`
                      : ''
                  }
                  isTextBeforeChildren={false}
                  callback={e => {
                    buttonCallback(e, true);
                  }}
                  linkAriaLabelOpenNewWindow={openInNewWindowAriaLabel}
                />
              )}
            </StyledModalFooter>
          </Modal.FooterGeneric>
        </Modal>
      )}
    </>
  );
});
